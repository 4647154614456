<template>
  <div class="login">
    <v-container fill-height fluid class="py-5">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4" class="mt-5">
          <v-stepper v-model="cotizacion" alt-labels>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                v-bind:complete="cotizacion > 1"
                class="text-center"
              >
                Enviar
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                step="2"
                v-bind:complete="cotizacion > 2"
                class="text-center"
                >Validar
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                step="3"
                v-bind:complete="cotizacion > 3"
                class="text-center"
                >Cambiar
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1" class="pt-0 pa-0">
                <v-card class="mt-2">
                  <v-card-title>
                    <span class="h6 ml-3">Enviar codigo</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form
                      ref="formRecuperar"
                      v-model="formulario.validar"
                      @submit.prevent="submit"
                    >
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="elem.email"
                              label="Email"
                              :rules="emailReglas"
                              required
                              outlined
                              clearable
                              @keydown.enter.prevent="modificar"
                              ref="FocusOrderDelivery"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :disabled="!formulario.validar"
                      :loading="formulario.cargar"
                      @click="modificar"
                    >
                      Siguiente
                      <v-icon right dark> mdi-arrow-right-bold </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="2" class="pt-0 pa-0">
                <v-card class="mt-2">
                  <v-card-title>
                    <div class="h6 ml-3">Validar codigo</div>
                    <div class="body-2 ml-3 mt-2 descripcion">
                      Se ha enviado un mensaje con el codigo de validación.
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="formRecuperar" v-model="formulario2.validar">
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="elem.codigo"
                              label="Codigo"
                              :rules="[(v) => !!v || 'Ingrese codigo valido']"
                              required
                              outlined
                              counter
                              maxlength="6"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :disabled="!formulario2.validar"
                      :loading="formulario2.cargar"
                      @click="validarCodigo"
                      >Validar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="3" class="pt-0 pa-0">
                <v-card class="mt-2">
                  <v-card-title> </v-card-title>
                  <v-card-text>
                    <v-form ref="formTipocambio" v-model="formulario3.validar">
                      <v-container>
                        <v-row>
                          <v-col cols="12" class="pb-0 pt-0">
                            <v-text-field
                              v-model="contrasena"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="[required, min6]"
                              :type="show1 ? 'text' : 'password'"
                              name="input-10-1"
                              label="Contraseña"
                              counter
                              @click:append="show1 = !show1"
                              outlined
                            ></v-text-field>
                            <v-text-field
                              v-model="contrasena2"
                              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="[required, min6, validarcontras]"
                              :type="show2 ? 'text' : 'password'"
                              name="input-10-1"
                              label="Confirmar contraseña"
                              counter
                              @click:append="show2 = !show2"
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="success"
                      :disabled="!formulario3.validar"
                      :loading="formulario3.cargar"
                      @click="cambiarContrasena"
                      >Modificar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialog2" max-width="450">
          <v-card>
            <v-avatar :color="colormensaje">
              <v-icon dark>
                {{ iconomensaje }}
              </v-icon>
            </v-avatar>
            <v-card-title class="text-h5 justify-center headerClass">
              {{ titulomensaje }}
            </v-card-title>
            <v-card-text
              class="pt-4 text-body-1 font-weight-medium text-justify"
              >{{ textomensaje }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cerrar"> Ok </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      show1: false,
      show2: false,
      textomensaje: "",
      iconomensaje: "",
      titulomensaje: "",
      colormensaje: "",
      dialog2: false,
      cotizacion: 1,
      enviar: 0,
      overlay: false,
      dialog: false,
      dialogoAbrir: false,
      formulario: {
        validar: true,
        cargar: false,
      },
      formulario2: {
        validar: true,
        cargar: false,
      },
      formulario3: {
        validar: true,
        cargar: false,
      },
      elem: {
        email: "",
        codigo: "",
        token: "",
      },
      contrasena: "",
      contrasena2: "",
      emailReglas: [
        (v) => !!v || "Email es requerido",
        (v) => /.+@.+/.test(v) || "Email no es valido",
      ],
    };
  },
  computed: {
    ...mapState({ alerta: (state) => state.alerta }),
    ...mapState("cliente", [
      "estado",
      "clientes",
      "resultado",
      "resultadoToken",
      "resultadoClave",
      "pruebam",
      "resultadoCliente"
    ]),
  },
  created() {
    console.log("aqui");
  },
  methods: {
    ...mapActions(
      "cliente",
      [
        "login",
        "logout",
        "enviarCorreo",
        "verificarCodigoEnviado",
        "cambiarclave",
      ],
      {
        clearAlert: "alert/clear",
      }
    ),
    iniciarsesion() {
      if (this.$refs.form.validate()) {
        const { email, contrasena } = this;
        if (email && contrasena) {
          this.login({ email, contrasena });
        }
      }
    },
    onChange() {
      this.alerta.message = null;
    },
    async modificar() {
      this.formulario.cargar = true;
      this.overlay = true;
      await this.enviarCorreo(this.elem);
      this.cotizacion = 2;
      this.formulario.cargar = false;
      this.overlay = false;
      if (this.resultado.coderror == "1") {
        this.dialog = true;
      } else {
        this.token = this.resultado.coderror;
        this.enviar = 1;
        console.log(this.token);
      }
    },
    async validar() {
      this.formulario.cargar = true;
      this.overlay = true;
      await this.enviarCorreo(this.elem);
      this.formulario.cargar = false;
      this.overlay = false;
      if (this.resultado.coderror == "1") {
        this.dialog = true;
      } else {
        this.enviar = 1;
      }
    },
    async validarCodigo() {
      let datos = {
        email: this.elem.email,
        token: this.resultadoToken,
        codigo: this.elem.codigo,
      };
      await this.verificarCodigoEnviado(datos);

      if(this.resultadoClave == "0"){
        this.cotizacion = 3

      } else if(this.resultadoClave == "1"){
         this.iconomensaje = "mdi-chat-alert-outline";
        this.titulomensaje = "Codigo no valido";
        this.textomensaje = "Por favor ingrese el mismo codigo que le fue enviado a su correo.";
        this.colormensaje = "yellow";
        this.dialog2 = true;
      } else {
         this.iconomensaje = "mdi-chat-alert-outline";
        this.titulomensaje = "Sesión no valida";
        this.textomensaje = "Por favor, solicite un nuevo codigo al correo";
        this.colormensaje = "yellow";
      }

    },
    async cambiarContrasena() {
      this.formulario.cargar = true;
      let datos = {
        email: this.elem.email,
        contrasena: this.contrasena,
      }
      await this.cambiarclave(datos);
      //console.log(this.pruebam)
      if (this.pruebam.coderror == "0") {
        this.iconomensaje = "mdi-check-circle-outline";
        this.titulomensaje = "Cambio de contraseña con exito";
        this.textomensaje =
          "Se realizó el cambio de su contraseña. Se re-direcionara al login.";
        this.colormensaje = "green";
        window.location.href = "https://app.cambio24peru.com/#/login";
      } else {
        this.iconomensaje = "mdi-chat-alert-outline";
        this.titulomensaje = "Error al realizar el cambio de contraseña";
        this.textomensaje = "Por favor intentelo nuevamente en unos minutos.";
        this.colormensaje = "yellow";
      }
      this.dialog2 = true;

      this.formulario.cargar = false;
    },
    required: function (value) {
      if (value) {
        return true;
      } else {
        return "La contraseña es requerida.";
      }
    },
    min6: function (value) {
      if (value.length >= 6) {
        return true;
      } else {
        return "Su contraseña debe tener más de 6 caractares.";
      }
    },
    validarcontras: function () {
      if (this.elem.contrasena === this.elem.contrasena2) {
        return true;
      } else {
        return "Las contraseñas no coinciden.";
      }
    },
    async cerrar() {
      this.dialog2 = false;
    },
    async evitar() {
      if (this.formulario.validar) {
        await this.modificar();
      } else {
        console.log("");
      }
    },
    async evitar2() {
      if (this.formulario.validar) {
        await this.validar();
      } else {
        console.log("");
      }
    },
  },
  watch: {},
};
</script>
<style>
.v-application--is-ltr .v-stepper__label {
  text-align: center !important;
}
.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-outer {
  margin-top: 2px;
}
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }
}
</style>