<template>
  <div align="center" justify="center" class="pb-10 mb-8">
    <div class="d-flex justify-center align-center pa-4 pl-6 pt-6 mt-5">
      <span class="rounded yellow pa-2 text-h6 font-weight-medium me-4"
        >!Bienvenido!</span
      >
      <br />
      <v-avatar slot="icon" color="yellow" size="40">
        <v-icon icon="mdi-lock" color="black">
          mdi-emoticon-excited-outline
        </v-icon>
      </v-avatar>
    </div>
    <div class="mb-3">
      <h1 class="text-h5">Registro de Personas Júridicas</h1>
    </div>
    <v-col cols="12" md="8" sm="12" lg="7" xl="4" class="px-0">
      <v-card class="ma-2 ma-sm-6 mt-1" max-width="480">
        <v-stepper v-model="cotizacion" alt-labels>
          <v-stepper-header>
            <v-stepper-step
              step="1"
              v-bind:complete="cotizacion > 1"
              class="text-center"
            >
              Datos de contacto
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="2"
              v-bind:complete="cotizacion > 2"
              class="text-center"
            >
              Datos de la Empresa
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content class="pt-4 px-3 px-sm-6" step="1">
              <v-card class="mt-1">
                <v-card-text class="pb-0 pt-0">
                  <div class="body-1 pb-4">
                    Llena los siguiente campos, para iniciar sesión.
                  </div>
                </v-card-text>
                <v-form ref="formCotizacion1" v-model="formulario.validar">
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-row>
                          <v-col cols="12" md="6" class="pb-0 pt-0">
                            <v-select
                              v-model="elem.ctipodocumento"
                              :items="tipos"
                              label="Tipo de documento"
                              :rules="[
                                (v) => !!v || 'Seleccione el tipo de documento',
                              ]"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col cols="12" class="pb-0 pt-0" md="6">
                            <v-text-field
                              v-model="elem.cnrodocumento"
                              label="Número de documento"
                              :rules="[(v) => !!v || 'El número es requerido']"
                              outlined
                              required
                              dense
                              ref="focusdocumentoContacto"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="pb-0 pt-0">
                            <v-text-field
                              v-model="elem.cnombres"
                              label="Nombres"
                              :rules="[(v) => !!v || 'El nombre es requerido']"
                              outlined
                              dense
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="pb-0 pt-0">
                            <v-text-field
                              v-model="elem.capaterno"
                              label="Apellido Paterno"
                              :rules="[
                                (v) =>
                                  !!v || 'El apellido parterno es requerido',
                              ]"
                              outlined
                              dense
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="pb-0 pt-0">
                            <v-text-field
                              v-model="elem.camaterno"
                              label="Apellido Materno"
                              :rules="[
                                (v) =>
                                  !!v || 'El apellido materno es requerido',
                              ]"
                              outlined
                              dense
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="pb-0 pt-0">
                            <v-text-field
                              v-model="elem.cemail"
                              :rules="emailReglas"
                              label="Email"
                              outlined
                              dense
                              required
                              ref="focusemail"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" class="pb-0 pt-0">
                            <v-text-field
                              ref="phone"
                              v-model="elem.ccelular"
                              label="Celular"
                              :rules="[
                                (v) =>
                                  !!v || 'Su numero de contacto es requerido',
                              ]"
                              outlined
                              dense
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="pb-0 pt-0">
                            <v-text-field
                              v-model="elem.contrasena"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="[required, min6]"
                              :type="show1 ? 'text' : 'password'"
                              name="input-10-1"
                              label="Contraseña"
                              counter
                              @click:append="show1 = !show1"
                              outlined
                              dense
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="elem.contrasena2"
                              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="[required, min6, validarcontras]"
                              :type="show2 ? 'text' : 'password'"
                              name="input-10-1"
                              label="Confirmar contraseña"
                              counter
                              @click:append="show2 = !show2"
                              outlined
                              dense
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
                <v-card-actions class="pt-4">
                  <v-btn
                    color="primary"
                    :disabled="!formulario.validar"
                    :loading="formulario.cargar"
                    @click="limpiar()"
                    @click.native="cotizacion = 2"
                  >
                    Siguiente
                    <v-icon right dark> mdi-arrow-right-bold </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card class="mt-1">
                <v-card-text class="pb-0 pt-0">
                  <div class="body-1 pb-4 text-center">
                    <div class="text-body-1">Llena los siguiente campos</div>
                    <small class="text-center"
                      >Estos datos son necesarios por la regulación de la
                      Superintendencia de Banca y Seguros (SBS) a la que estamos
                      sujetos.</small
                    >
                  </div>
                </v-card-text>
                <v-form
                  ref="formCotizacion2"
                  v-model="formulario2.validar"
                  class="pb-4"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-row>
                          <v-col cols="12" class="pb-0">
                            <v-row>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-text-field
                                  v-model="elem.ruc"
                                  label="RUC"
                                  :rules="[
                                    (v) => !!v || 'El RUC es requerido',
                                    (v) =>
                                      v.length >= 11 ||
                                      'El RUC debe de tener 11 caracteres',
                                  ]"
                                  maxlength="11"
                                  outlined
                                  counter
                                  required
                                  dense
                                  ref="focusdocumento"
                                >
                                <template v-slot:append>
                                    <v-fade-transition hide-on-leave>
                                      <v-progress-circular
                                        v-if="elemRUC.cargando"
                                        size="24"
                                        color="info"
                                        indeterminate
                                      ></v-progress-circular>
                                    </v-fade-transition>
                                  </template>
                                  <template v-slot:append-outer>
                                    <v-btn color="primary" @click="verficarruc" small>
                                      <v-icon left> mdi-magnify </v-icon>
                                      Buscar
                                    </v-btn>
                                  </template>                                
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-text-field
                                  v-model="elem.razonsocial"
                                  label="Razón social"
                                  :rules="[
                                    (v) =>
                                      !!v || 'La razón social es requerida',
                                  ]"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                             
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-text-field
                                  v-model="elem.direccion"
                                  label="Dirección"
                                  :rules="[
                                    (v) => !!v || 'El dirección es requerida',
                                  ]"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-select
                                  v-model="elemUbigeo.departamento"
                                  :items="listadepartamentos"
                                  item-text="nombre_ubigeo"
                                  item-value="id_ubigeo"
                                  label="Departamento"
                                  return-object
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'Seleccione el departamento registrado',
                                  ]"
                                  @change="cambiarDepartamento"
                                  outlined
                                  dense
                                  ref="focusdepartamento"
                                ></v-select>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-select
                                  v-model="elemUbigeo.provincia"
                                  :items="listaprovincias"
                                  item-text="nombre_ubigeo"
                                  item-value="id_ubigeo"
                                  label="Provincia"
                                  return-object
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'Seleccione la provincia registrada',
                                  ]"
                                  @change="cambiarProvicia"
                                  outlined
                                  dense
                                >
                                  <template v-slot:no-data>
                                    <div class="px-4 pb-3 mt-4">
                                      Seleccione el departamento...
                                    </div>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-select
                                  v-model="elemUbigeo.distrito"
                                  :items="listadistritos"
                                  item-text="nombre_ubigeo"
                                  item-value="id_ubigeo"
                                  label="Distrito"
                                  return-object
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'Seleccione el distrito registrado',
                                  ]"
                                  @change="cambiarDistrito"
                                  outlined
                                  dense
                                >
                                  <template v-slot:no-data>
                                    <div class="px-4 pb-3 mt-4">
                                      Seleccione el departamento y la
                                      provincia...
                                    </div>
                                  </template>
                                </v-select>
                              </v-col>
                               <v-col cols="12" class="pb-0 pt-0">
                                <v-text-field
                                  v-model="elem.telefono"
                                  label="Teléfono"
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'El el número de telefono es requerido',
                                  ]"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-text-field
                                  v-model="elem.gironegocio"
                                  label="Actividad económica principal"
                                  :rules="[
                                    (v) =>
                                      !!v || 'Ingrese su actividad económica',
                                  ]"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-5 pt-0">
                                <div class="text-start">
                                  Datos del Representante legal
                                </div>
                              </v-col>
                              <v-col cols="12" md="6" class="pb-0 pt-0">
                                <v-select
                                  v-model="elem.rptipodocumento"
                                  :items="tipos"
                                  label="Tipo de documento"
                                  :rules="[
                                    (v) =>
                                      !!v || 'Seleccione el tipo de documento',
                                  ]"
                                  dense
                                  outlined
                                ></v-select>
                              </v-col>

                              <v-col cols="12" class="pb-0 pt-0" md="6">
                                <v-text-field
                                  v-model="elem.rpnrodocumento"
                                  label="Número de documento"
                                  :rules="[
                                    (v) => !!v || 'El número es requerido',
                                  ]"
                                  outlined
                                  required
                                  dense
                                  ref="focusdocumentoRP"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-text-field
                                  v-model="elem.rpnombres"
                                  label="Nombres"
                                  :rules="[
                                    (v) => !!v || 'El nombre es requerido',
                                  ]"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-text-field
                                  v-model="elem.rpapaterno"
                                  label="Apellido Paterno"
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'El apellido parterno es requerido',
                                  ]"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-text-field
                                  v-model="elem.rpamaterno"
                                  label="Apellido Materno"
                                  :rules="[
                                    (v) =>
                                      !!v || 'El apellido materno es requerido',
                                  ]"
                                  outlined
                                  dense
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-select
                                  v-model="elem.rpocupacion"
                                  :items="ocupaciones"
                                  label="Ocupación"
                                  :rules="[
                                    (v) => !!v || 'La ocupación es requerida',
                                  ]"
                                  outlined
                                  dense
                                ></v-select>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-checkbox
                                  class="mt-0"
                                  v-model="declarocheck"
                                  :rules="[
                                    (v) => !!v || 'Debe aceptar la declaración',
                                  ]"
                                  required
                                >
                                  <template v-slot:label>
                                    <div>
                                      Declaro bajo juramento que la información
                                      brindada es verdadera y exacta
                                    </div>
                                  </template>
                                </v-checkbox>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0">
                                <v-checkbox
                                  class="mt-0"
                                  v-model="aceptocheck"
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'Debe aceptar los términos, condicones y política',
                                  ]"
                                  required
                                >
                                  <template v-slot:label>
                                    <div>
                                      Al registrarse, acepta los
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <a
                                            target="_blank"
                                            href="https://cambios24peru.com/terminos-y-condiciones/"
                                            @click.stop
                                            v-on="on"
                                          >
                                            Términos y condiciones
                                          </a>
                                        </template>
                                        Abrir en otra pestaña
                                      </v-tooltip>
                                      y la
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <a
                                            target="_blank"
                                            href="https://cambios24peru.com/politicas-de-privacidad/"
                                            @click.stop
                                            v-on="on"
                                          >
                                            Política de privacidad
                                          </a>
                                        </template>
                                        Abrir en otra pestaña
                                      </v-tooltip>
                                      de Cambios 24.
                                    </div>
                                  </template>
                                </v-checkbox>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
                <v-card-actions class="pt-5">
                  <v-btn
                    text
                    @click="retornar1()"
                    @click.native="cotizacion = 1"
                  >
                    <v-icon left dark> mdi-keyboard-return </v-icon>
                    Regresar
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="!formulario2.validar"
                    @click="agregar"
                  >
                    Registrar
                    <v-icon right dark> mdi-arrow-right-bold </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-col>
    <v-row justify="center" aling="center">
      <v-dialog v-model="dialog" persistent max-width="450">
        <v-card>
          <v-avatar color="green">
            <v-icon dark> mdi-check-underline </v-icon>
          </v-avatar>
          <v-card-title class="text-h5 justify-center">
            Cliente registrado con exito.
          </v-card-title>
          <v-card-text class="text-body-1 font-weight-medium text-center"
            >Gracias por registrarte, ya puedes acceder a nuestros servicios de
            cambio, empieza ahora.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              href="https://app.cambio24peru.com/"
              @click="dialog = false"
            >
              Empezar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialog2" persistent max-width="450">
        <v-card>
          <v-avatar color="red">
            <v-icon dark> mdi-cancel </v-icon>
          </v-avatar>
          <v-card-title class="text-h5 justify-center headerClass">
            {{ mensajes[mensajeSeleccionado].titulo }}
          </v-card-title>
          <v-card-text class="pt-4 text-body-1 font-weight-medium text-justify"
            >{{ mensajes[mensajeSeleccionado].descripcion }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="green darken-1"
              text
              to="recuperar-contrasena"
              @click="dialog2 = false"
            >
              Recuperar contraseña
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="limpiar">
              Intentar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { ubigeo } from "../_extra/ubigeo";

export default {
  data: () => ({
    elemRUC: {
      cargando: false,
      mensaje: "Número de documento",
      dialog: false,
    },
    declarocheck: false,
    aceptocheck: false,
    mostrarPep: false,
    overlay: false,
    dialog: false,
    dialog2: false,
    show1: false,
    show2: false,
    cotizacion: 1,
    formulario: {
      validar: true,
      cargar: false,
    },
    formulario2: {
      validar: true,
      cargar: false,
    },
    elem: {
      razonsocial: "",
      ruc: "",
      direccion: "",
      telefono: "",
      pais: "",
      gironegocio: "",
      habilitado: "SI",
      departamento: "",
      provincia: "",
      distrito: "",
      rptipodocumento: "DNI",
      rpnrodocumento: "",
      rpnombres: "",
      rpapaterno: "",
      rpamaterno: "",
      rpocupacion: "",
      cnombres: "",
      capaterno: "",
      camaterno: "",
      cemail: "",
      ccelular: "",
      ctipodocumento: "DNI",
      cnrodocumento: "",
      contrasena: "",
      contrasena2: "",
    },
    elemUbigeo: {
      departamento: "",
      provincia: "",
      distrito: "",
    },
    emailReglas: [
      (v) => !!v || "Email es requerido",
      (v) => /.+@.+/.test(v) || "Email no es valido",
    ],
    tipos: [
      "DNI",
      "RUC",
      "CARNET EXTRANJERIA",
      "CÉDULA CIUDADANA",
      "PASAPORTE",
      "CÉDULA DIPLOMATICA",
    ],
    ocupaciones: [
      "Abogado (a)",
      " Administrador(a)",
      "Agricultor(a)",
      "Ama de casa",
      "Arquitecto(a)",
      "Artista",
      "Cambista",
      "Comerciante",
      "Consultor(a)",
      "Contador(a)",
      "Diseñador(a)",
      "Docente",
      "Economista",
      "Empresario(a)",
      "Enfermero(a)",
      "Estudiante",
      "Funcionario Público",
      "Importador o exportador",
      "Ingeniera(o)",
      "Ingeniera(o)",
      "Jubilado",
      "Médico",
      "Militar",
      "Obstetriz",
      "Odontólogo(a)",
      "Periodista",
      "Policía",
      "Psicólogo(a)",
      "Publicista",
      "Seguridad",
      "Trabajador dependiente",
      "Trabajador Independiente",
      "Trabajador(a) Social",
      "Vendedor(a)",
      "Otros",
    ],
    peps: ["SI", "NO"],
    listadepartamentos: [],
    listaprovincias: [],
    listadistritos: [],
    mensajes: [
      {
        titulo: "El correo electrónico de contacto ya esta registrado.",
        descripcion:
          "Su información de correo electrónico de contacto ya esta en nuestra central. Tal vez desea recuperar la constraseña o desea intentarlo de nuevo ingresando otro correo electrónico.",
        icono: "mdi-alert",
      },
      {
        titulo: "El número de documento de contacto ya esta registrado.",
        descripcion:
          "Su información de documento de contacto ya esta en nuestra central. Tal vez desea recuperar la constraseña o desea intentarlo de nuevo ingresando otro documento de contacto.",
        icono: "mdi-alert",
      },
      {
        titulo: "El RUC ya esta registrado.",
        descripcion:
          "Su información de RUC ya esta en nuestra central. Tal vez desea recuperar la constraseña o desea intentarlo de nuevo ingresando otro RUC.",
        icono: "mdi-alert",
      },
    ],
    mensajeSeleccionado: 0,
  }),
  async created() {
    this.iniciar();
    this.cargarDepartamento();
  },
  computed: {
    ...mapState("empresa", ["resultado","resultadoAgregar", "resultadoRUC"]),
  },
  methods: {
    ...mapActions("empresa", ["guardar", "consultarRUC"]),
    limpiar() {
      setTimeout(() => {
        window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
        if (this.mensajeSeleccionado == 0) {
          this.$refs.focusemail.focus();
        } else if(this.mensajeSeleccionado == 1){
          this.$refs.focusdocumentoContacto.focus()
        } else {
          this.$refs.focusdocumento.focus()
        }
      }, 200);
      this.dialog2 = false;
    },
    iniciar() {
      setTimeout(() => {
        this.$refs.focusdocumentoContacto.focus();
      }, 200);
      this.dialog2 = false;
    },
    async agregar() {
      this.formulario.cargar = true;
      this.overlay = true;
      await this.guardar(this.elem);

      this.overlay = false;
      //console.log(this.resultadoAgregar);

      if (this.resultadoAgregar.coderror != "0") {
        this.dialog2 = true;

        if (this.resultadoAgregar.coderror == "1") {
          this.cotizacion = 1
          this.mensajeSeleccionado = 0;
        } else if (this.resultadoAgregar.coderror == "2") {
          this.cotizacion = 1
          this.mensajeSeleccionado = 1;
        } else {
          this.cotizacion = 2
          this.mensajeSeleccionado = 2;
        }
      } else {
        this.dialog = true;
        this.$refs.formCotizacion2.reset();
        this.elem.ctipodocumento = "DNI"
        this.elem.cnrodocumento = ""
        this.elem.cemail = ""
        this.elem.ccelular = ""
        this.contrasena = ""
        this.contrasena2 = ""
      }
      this.formulario.cargar = false;
    },
    required: function (value) {
      if (value) {
        return true;
      } else {
        return "La contraseña es requerida.";
      }
    },
    min6: function (value) {
      if (value.length >= 6) {
        return true;
      } else {
        return "Su contraseña debe tener más de 6 caractares.";
      }
    },
    validarcontras: function () {
      if (this.elem.contrasena === this.elem.contrasena2) {
        return true;
      } else {
        return "Las contraseñas no coinciden.";
      }
    },
    cargarDepartamento() {
      //console.log(ubigeo().resultado.departamentos)
      this.listadepartamentos = ubigeo().resultado.departamentos;
      this.listaprovincias = [];
      this.listadistritos = [];
    },
    cambiarDepartamento(item) {
      //console.log(item)
      this.listaprovincias = ubigeo().resultado.provincias[item.id_ubigeo];
      this.listadistritos = [];
      this.elem.departamento = item.nombre_ubigeo;
    },
    cambiarProvicia(item) {
      this.listadistritos = ubigeo().resultado.distritos[item.id_ubigeo];
      this.elem.provincia = item.nombre_ubigeo;
    },
    cambiarDistrito(item) {
      this.elem.distrito = item.nombre_ubigeo;
    },
    validarPep(item) {
      if (item == "SI") {
        this.mostrarPep = true;
      } else {
        this.mostrarPep = false;
      }
    },
    retornar1() {
      this.iniciar();
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 200);
    },
    async verficarruc() {
      this.elemRUC.cargando = true;
      this.elemRUC.mensaje = "verificando...";
      await this.consultarRUC(this.elem.ruc);
      this.elemRUC.cargando = false;
      this.elemRUC.mensaje = `Número de documento `;

      if (this.resultadoRUC.razonSocial != null) {
        this.elem.razonsocial = this.resultadoRUC.razonSocial;
        this.elem.direccion = this.resultadoRUC.direccion;
        setTimeout(() => {
          this.$refs.focusdepartamento.focus();
        }, 200);
      } else {
        this.elemRUC.dialog = true;        
      }
    },
  },
};
</script>
<style>
.v-application--is-ltr .v-stepper__label {
  text-align: center !important;
}
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }
}
</style>