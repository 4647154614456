<template>
    <v-dialog
        v-model="dialogoAbrir"
        max-width="750px"
    >
        <v-card>
            <v-card-title>
                <span class="headline">Modificar perfil</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEmpresa" v-model="formulario.validar">
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    v-model="elem.razonsocial"
                                    label="Razon social"
                                    :rules="[v => !!v || 'La Razón social es requerida']"
                                    required
                                    ref="FocusOrderDelivery"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                            >
                                <v-text-field
                                    v-model="elem.ruc"
                                    label="RUC"
                                    :rules="[v => !!v || 'El número de RUC es requerido']"
                                    :counter="11"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                            >
                                <v-text-field
                                    v-model="elem.direccion"
                                    label="Dirección"
                                    :rules="[v => !!v || 'La direccion es requerida']"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                            >
                                <v-text-field
                                    v-model="elem.email"
                                    :rules="emailReglas"
                                    label="E-mail"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                            >
                                <v-text-field
                                    v-model="elem.telefono"
                                    label="Telefono / Celular"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                            >
                                <v-text-field
                                    v-model="elem.pais"
                                    label="Pais"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                            >
                                <v-text-field
                                    v-model="elem.gironegocio"
                                    label="Giro del negocio"
                                    :rules="[v => !!v || 'El giro del negocio es requerido']"
                                    required
                                ></v-text-field>
                            </v-col>
                        
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" :disabled="!formulario.validar" :loading="formulario.cargar" @click="modificar">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {mapState, mapActions} from 'vuex'
  export default {
    name: "empresa.editar",
    data: () => ({
      dialogoAbrir: false,
      formulario : {
        validar: true,
        cargar: false
      },
      elem : {
        id: '',
        razonsocial: '',
        ruc: '',
        direccion: '',
        email: '',
        telefono: '',
        pais: '',
        gironegocio: '',
        habilitado: 'SI',
        idcliente: ''
      },
      emailReglas: [
        v => !!v || 'Email es requerido',
        v => /.+@.+/.test(v) || 'Email no es valido',
      ]
    }),
    created() {
                
    },
    computed: {
        ...mapState('empresa', ['all', 'empresaEditar']),
    },
    methods: {
        ...mapActions('empresa', ['editar', 'listarEmpresasPorCliente']),
        async mostrarDialogo() {
            this.elem = this.empresaEditar
            this.dialogoAbrir = true
            //console.log(this.$refs)
            //this.$refs.FocusOrderDelivery.focus();
            setTimeout(() => {
                this.$refs.FocusOrderDelivery.focus()
            }, 200)
            //await this.listarEmpresasPorCliente(this.clientesele.id)
        },
        async modificar() {
            this.formulario.cargar = true
            await this.editar(this.elem)
            await this.listarEmpresasPorCliente(this.elem.id)
            this.formulario.cargar = false
            this.dialogoAbrir = false
        }
    }
  }
</script>