import router from '../router'
import { direccion } from '../_extra/configuracion'

const clientes = JSON.parse(localStorage.getItem('cliente'))

const state = clientes 
    ? { estado: { logueado: true }, clientes,  clientesele:null, resultadoCliente: {}, resultadoDNI: {}, resultadoToken: '', resultadoClave: '',pruebam:''}
    : { estado: {}, clientes:null, clientesele:null, resultado:null, resultadoCliente: {}, resultadoClave: '',pruebam:'' }

const actions = {
    async login ({dispatch, commit},{email, contrasena}) {
        commit('loginPedido', {email})
        const pedidoOpciones = {
            method: 'POST',
            headers: { 'Content-type':'application/json' },
            body: JSON.stringify({ email, contrasena})
        }
        try {
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=autenticar`, pedidoOpciones)
            const contenido = await respuesta.json()
            console.log(contenido)
            if(contenido.nombres != null) {
                localStorage.setItem('cliente', JSON.stringify(contenido))
                commit('loginExitoso', contenido)
                router.push('/')
            } else {
                commit('loginFallido', 'Usuario y/o contraseña incorrectos')
                dispatch('alerta/error', 'Usuario y/o contraseña incorrectos', {root:true})
            }
        } catch (error) {
            console.error(error)
        }
    },
    logout({commit}) {
        localStorage.removeItem('cliente')
        console.log("primer: " + state.estado.logueado)
        commit('logout')
    },
    async listarClientePorId({commit}, idcliente) {
        const opciones = {
            method: 'GET',
        }
        try {
            let respuesta = await fetch(`${direccion().url}/clientes.php?id=${idcliente}`, opciones)
            const contenido = await respuesta.json()
            commit('obtenerClientePorId', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async agregar({commit}, elemento) {
        
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elemento)
        }
        try {
            //console.log(JSON.stringify(elemento))
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=ag002`, opciones)
            const contenido = await respuesta.json()
            console.log(contenido)
            commit('agregarCliente', contenido);
            
        } catch (error) {
            //console.log(error.message)
            console.error(error)
        }
    },
    async editar({commit}, elementoEditar) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoEditar)
        }
        try {
            //console.log(elementoEditar.id)
             await fetch(`${direccion().url}/clientes.php?id=${elementoEditar.id}`, opciones)
            commit('editarCliente', elementoEditar);
        } catch (error) {
            console.error(error)
        }
    },
    async enviarCorreo({commit}, elemento) {
        
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elemento)
        }
        try {
            //console.log(JSON.stringify(elemento))
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=ag005`, opciones)
            const contenido = await respuesta.json()
            commit('enviarCorreoCliente', contenido);
        } catch (error) {   
            console.error(error)
        }
    },
    async consultarDNI({commit}, elemento) {
        
        const opciones = {
            method: 'GET',
        }
        try {
            //console.log(`${direccion().url}/clientes.php?accion=li004&nrodocumento=${elemento}`)
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=li004&nrodocumento=${elemento}`, opciones)
            const contenido = await respuesta.json()
            commit('enviarConsultarDNI', contenido);
        } catch (error) {   
            console.error(error)
        }
    },
    async verificarCodigoEnviado({commit}, elemento) {
        
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elemento)
        }
        try {
            console.log(JSON.stringify(elemento))
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=ag007`, opciones)
            const contenido = await respuesta.json()
            
            commit('comprobarCodigoEnviado', contenido);
        } catch (error) {   
            console.error(error)
        }
    },
    async cambiarclave({commit}, elementoEditar) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoEditar)
        }
        try {
            //console.log(elementoEditar)
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=ed004`, opciones)
            const contenido = await respuesta.json()
            commit('cambiarclaveCliente', contenido);
        } catch (error) {
            console.error(error)
        }
    },
}

const mutations = {
    loginPedido(state, cliente) {
        state.clientes = cliente
    },
    loginExitoso(state, cliente) {
        state.estado = {logueado:true}
        state.clientes = cliente
    },
    loginFallido(state) {
        state.estado = {}
        state.clientes = null
    },
    logout(state) {
        //state.estado = { logueado = false}
        state.clientes = null
        state.estado.logueado = false
    },
    obtenerClientePorId(state, cliente) {
        state.clientesele = cliente
    },
    editarCliente(state, cliente) {
        state.clientes = cliente
    },
    agregarCliente(state, resul) {
        state.resultadoCliente = resul
    },
    enviarCorreoCliente(state, resul){
        state.resultado = resul
        state.resultadoToken = resul.coderror
    },
    enviarConsultarDNI(state, resul){
        state.resultadoDNI = resul
    },
    comprobarCodigoEnviado(state, resul){
        state.resultadoClave = resul.coderror
    }, 
    cambiarclaveCliente(state, respuesta) {
        state.pruebam = respuesta
    },
}

export const cliente = {
    namespaced: true,
    state,
    actions,
    mutations
}