import { direccion } from '../_extra/configuracion'

const state = {
    all: {},
    cuentabancaria: [],
    cuentabancariacliente: [],
    cuentabancariaeditar: [],
    cuentaenvio: 2,
}

const actions = {
    async listarCuentasPorCliente({commit}, idcliente) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/cuentabancaria.php?idcliente=${idcliente}`, opciones)
            const contenido = await response.json()
            commit('obtenerCuentaCliente', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async agregar({commit}, elementoAgregar) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoAgregar)
        }
        try {
            let respuesta = await fetch(`${direccion().url}/cuentabancaria.php`, opciones)
            const contenido = await respuesta.json()
            commit('agregarCuentabancaria', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async editar({commit}, elementoEditar) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoEditar)
        }
        try {
            await fetch(`${direccion().url}/cuentabancaria.php?id=${elementoEditar.id}`, opciones)
            commit('editarCuentabancaria', elementoEditar);
        } catch (error) {
            console.error(error)
        }
    }, 
    async eliminar({commit}, id) {
        const opciones = {
            method: 'DELETE',
        }
        try {
            let respuesta = await fetch(`${direccion().url}/cuentabancaria.php?id=${id}`, opciones)
            const contenido = await respuesta.json()
            commit('eliminarCuentabancaria', contenido) 
        } catch (error) {
            console.error(error)
        }
    },
    asignarCuentabancariaEditar({commit}, elementoEmpresa) {
        try {
            commit('asignarCuentabancaria', elementoEmpresa)
        } catch (error) {
            console.error(error)
        }
    },
    asignarCuentaenlista({commit}, elemento) {
        try {
            commit('asignarCuentaLista', elemento)
        } catch (error) {
            console.error(error)
        }
    }
}

const mutations = {
    obtenerCuentaCliente(state, contenido) {
        state.all = {resultado : contenido}
        state.cuentabancariacliente = contenido
    },
    agregarCuentabancaria(state, contenido) {
        state.all = {resultado : contenido}
    },
    asignarCuentabancaria(state, contenido) {
        state.cuentabancariaeditar = contenido
    },
    asignarCuentaLista(state, contenido) {
        console.log(contenido)
        state.cuentaenvio = contenido
    },
    editarCuentabancaria(state, contenido) {
        state.cuentabancaria = contenido
    }, 
    eliminarCuentabancaria(state, contenido) {
        state.all = {resultado: contenido}
    },
}

export const cuentabancaria = {
    namespaced: true,
    state,
    actions,
    mutations
}