import { direccion } from '../_extra/configuracion'

const state = {
    oresultado: {},
    operaciones: []
}

const actions = {
    async listarOperaciones({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/operaciones.php`, opciones)
            const contenido = await response.json()
            commit('obtnerOperaciones', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async registrar({commit}, elementoRegistrar) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoRegistrar)
        }
        try {
            let respuesta = await fetch(`${direccion().url}/operaciones.php?enviar=2021`, opciones)
            const contenido = await respuesta.json()
            commit('registrarOperacion', contenido)
        } catch (error) {
            console.error(error)
        }
    }
}

const mutations = {
    obtnerOperaciones(state, contenido) {
        state.oresultado = {resultado : contenido}
        state.operaciones = contenido
    },
    registrarOperacion(state, contenido) {
        state.oresultado = {resultado : contenido}
    },
}

export const operacion = {
    namespaced: true,
    state,
    actions,
    mutations
}