import Vue from 'vue'
import Vuex from 'vuex'

import { alerta } from './Alerta.modulo'
import { cliente } from './Cliente.modulo'
import { empresa } from './Empresa.modulo'
import { cuentabancaria } from './Cuentabancaria.modulo'
import { tipocambio } from './Tipocambio.modulo'
import { cuentausuario } from './Cuentausuario.modulo'
import { operacion } from './Operacion.modulo'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    alerta,
    cliente,
    empresa,
    cuentabancaria,
    tipocambio,
    cuentausuario,
    operacion
  }
})
