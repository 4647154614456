import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Persona from '../views/Persona.vue'
import Empresa from '../views/Empresa.vue'
import Reestablecerclave from '../views/Reestablecer-clave.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/persona',
    name: 'Persona',
    component: Persona
  },
  {
    path: '/empresa',
    name: 'Empresa',
    component: Empresa
  },
  {
    path: '/reestablecer-clave',
    name: 'Reestablecer-clave',
    component: Reestablecerclave
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
