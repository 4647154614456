<template>
  <v-app id="app">
    <Barmenu />
    <v-main>
        <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>

import { mapState } from 'vuex'

import Barmenu from './components/Barmenu';
import Footer from './components/Footer.vue';

export default {
  name: 'App',

  components: {
    Barmenu,
    Footer
  },

  data: () => ({
    logueado:false
    //
  }),
  computed: {
    ...mapState('cliente', ['estado'])
  }
};
</script>
<style>
#app {
    background-color: #f8f8f8;
}
</style>