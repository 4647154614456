<template>
<v-container  fluid ma-0 pa-0 fill-heigh>
    <v-card color="grey lighten-4" flat height="50px" tile>
     <v-app-bar
      color="white darken-3"
      dark
      fixed
    >

        <v-toolbar-title class="ml-10 mt-2"><a href="https://cambio24peru.com/"><img src="../assets/logo-cambio-24-whitebg.png" alt="" width="130" class="pl-5"></a></v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn  large
          class=" red--white mr-5" style="opacity: 0.8;" href="./" >
            Inicio
        </v-btn>
     </v-app-bar>
    </v-card>

    
</v-container>
</template>

<script>

import { mapState, mapActions} from 'vuex'

export default {
    name: "Barmenu",
    data() {
    return {
            drawer: true,
            items: [
            { title: "Inicio", icon: "mdi-home", ruta: "/" },
            { title: "Perfiles", icon: "mdi-account-supervisor-circle", ruta: "/perfil" },
            { title: "Operaciones", icon: "mdi-cash-multiple", ruta: "/operaciones" },
            { title: "Cuentas", icon: "mdi-account-cash", ruta: "/cuentas" },
            { title: "Cambiar contraseña", icon: "mdi-form-textbox-password", ruta: "/cambiar-clave" },
            
            ],
            color: "white",
            colors: ["orange"],
            right: false,
            permanent: false,
            miniVariant: false,
            background: false,
            cnombres: '',
            capaterno: '',
            camaterno: '',
            cemail: '',
            idcliente: ''
        };
    },
    computed: {
        bg() {
            return this.background
            ? "https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
            : undefined;
        },
         ...mapState('cliente', ['clientes', 'estado'])
    }, 
    created () {
        //console.log(this.user)
        if(this.estado.logueado){
          this.cemail = this.clientes.email
          this.cnombres = this.clientes.nombres
          this.capaterno = this.clientes.apaterno
          this.camaterno = this.clientes.amaterno
        }
    },
    methods: {
      ...mapActions('cliente', ['logout']),
        estadoBar() {
            this.miniVariant = !this.miniVariant
            this.showapp = !this.showapp
        },
        async cerrarSesion() {
            await this.logout()
            this.$router.push('/login')
        }
    }
}
</script>