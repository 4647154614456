<template>
  <div align="center" justify="center" class="pb-10 mb-8">
    <div class="pa-4 pl-6 pt-6 mt-5">
      <v-avatar slot="icon" color="yellow" size="40" class="mt-4">
        <v-icon icon="mdi-lock" color="black">
          mdi-emoticon-excited-outline
        </v-icon>
      </v-avatar>
      <div class="py-3">
        <h1 class="text-h5 font-weight-bold">Regístrate</h1>
        <div class="subtitle-1 pt-2">
          Empieza registrando tus datos, contamos con las siguientes opciones:
        </div>
      </div>
    </div>
    <v-row :justify="'center'" no-gutters>
      <v-col md="2">
        <v-card class="p-5 py-5 mx-2 "  href="#/persona" >
          <v-card-text class="pb-0" >
            <v-icon x-large right color="secondary"> mdi-account-plus </v-icon>
            <div class="text-h6">Como Persona Natural</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="2">
        <v-card class="p-5 py-5 mx-2" href="#/empresa" >
          <v-card-text class="pb-0" >
            <v-icon x-large right color="secondary"> mdi-domain </v-icon>
            <div class="text-h6">Como Persona Júridica</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-row justify="center" aling="center">
      <v-dialog v-model="dialog" persistent max-width="450">
        <v-card>
          <v-avatar color="green">
            <v-icon dark> mdi-check-underline </v-icon>
          </v-avatar>
          <v-card-title class="text-h5 justify-center">
            Cliente registrado con exito.
          </v-card-title>
          <v-card-text class="text-body-1 font-weight-medium text-center"
            >Gracias por registrarte, ya puedes acceder a nuestros servicios de
            cambio, empieza ahora.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              href="https://app.tuscambioscusco.com/login"
              @click="dialog = false"
            >
              Empezar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialog2" persistent max-width="450">
        <v-card>
          <v-avatar color="red">
            <v-icon dark> mdi-cancel </v-icon>
          </v-avatar>
          <v-card-title class="text-h5 justify-center headerClass">
            El DNI o Correo ya se encuentran registrados.
          </v-card-title>
          <v-card-text class="pt-4 text-body-1 font-weight-medium text-justify"
            >Su información de Documento y/o Correo electronico ya esta en
            nuestra central. Tal vez desea recuperar la constraseña o ¿quiere
            intentarlo nuevamente?
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="green darken-1"
              text
              to="recuperar-contrasena"
              @click="dialog2 = false"
            >
              Recuperar contraseña
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="limpiar">
              Intentar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row>
      <empresa-crear ref="dialogoEmpresaCrear" />
    </v-row>
    <v-row>
      <empresa-editar ref="dialogoEmpresaEditar" />
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import EmpresaCrear from "../components/empresa.crear.vue";
import EmpresaEditar from "../components/empresa.editar.vue";
import { ubigeo } from "../_extra/ubigeo";

export default {
  components: {
    EmpresaCrear,
    EmpresaEditar,
  },
  data: () => ({
    mostrarPep: false,
    overlay: false,
    dialog: false,
    dialog2: false,
    show1: false,
    show2: false,
    formulario: {
      validar: true,
      cargar: false,
    },
    elem: {
      nombres: "",
      apaterno: "",
      amaterno: "",
      direccion: "",
      email: "",
      celular: "",
      tipodocumento: "",
      nrodocumento: "",
      nacionalidad: "",
      pep: "",
      ocupacion: "",
      habilitado: "",
      contrasena: "",
      contrasena2: "",
      estadocivil: "",
      institucion: "",
      cargo: "",
      fechanacimiento: "",
      departamento: "",
      provincia: "",
      distrito: "",
    },
    emailReglas: [
      (v) => !!v || "Email es requerido",
      (v) => /.+@.+/.test(v) || "Email no es valido",
    ],
    tipos: [
      "DNI",
      "RUC",
      "CARNET EXTRANJERIA",
      "CÉDULA CIUDADANA",
      "PASAPORTE",
      "CÉDULA DIPLOMATICA",
    ],
    ocupaciones: [
      "Abogado (a)",
      " Administrador(a)",
      "Agricultor(a)",
      "Ama de casa",
      "Arquitecto(a)",
      "Artista",
      "Cambista",
      "Comerciante",
      "Consultor(a)",
      "Contador(a)",
      "Diseñador(a)",
      "Docente",
      "Economista",
      "Empresario(a)",
      "Enfermero(a)",
      "Estudiante",
      "Funcionario Público",
      "Importador o exportador",
      "Ingeniera(o)",
      "Ingeniera(o)",
      "Jubilado",
      "Médico",
      "Militar",
      "Obstetriz",
      "Odontólogo(a)",
      "Periodista",
      "Policía",
      "Psicólogo(a)",
      "Publicista",
      "Seguridad",
      "Trabajador dependiente",
      "Trabajador Independiente",
      "Trabajador(a) Social",
      "Vendedor(a)",
      "Otros",
    ],
    peps: ["SI", "NO"],
    listadepartamentos: [],
    listaprovincias: [],
    listadistritos: [],
  }),
  async created() {
    this.elem.tipodocumento = "DNI";
    await this.llenarCliente();

    //console.log(this.$route.query.test);
    this.cargarDepartamento();
    console.log(ubigeo().resultado);
  },
  computed: {
    ...mapState("cliente", ["clientes", "clientesele", "resultado"]),
    diplayClass() {
      let classString = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          classString = "subtitle-2 text-no-wrap justify-center";
          break;
        case "sm":
          classString = "subtitle-1 justify-center";
          break;
        case "md":
          classString = "h5 justify-center";
          break;
        case "lg":
          classString = "h6 justify-center";
          break;
        case "xl":
          classString = "h6 justify-center";
          break;
      }
      return classString;
    },
  },
  methods: {
    ...mapActions("cliente", ["listarClientePorId", "agregar"]),
    async llenarCliente() {},
    limpiar() {
      this.elem.nrodocumento = "";
      setTimeout(() => {
        this.$refs.focusdocumento.focus();
      }, 200);
      this.dialog2 = false;
    },
    async guardar() {
      this.formulario.cargar = true;
      this.overlay = true;
      await this.agregar(this.elem);
      this.formulario.cargar = false;
      this.overlay = false;
      if (this.resultado.coderror != "0") {
        this.dialog2 = true;
      } else {
        this.dialog = true;
      }
    },
    nuevaempresa() {
      this.$refs.dialogoEmpresaCrear.mostrarDialogo();
    },
    editarEmpresa(ele) {
      //console.log(ele)
      this.asignarEmpresaEditar(ele);
      this.$refs.dialogoEmpresaEditar.mostrarDialogo();
    },
    required: function (value) {
      if (value) {
        return true;
      } else {
        return "La contraseña es requerida.";
      }
    },
    min6: function (value) {
      if (value.length >= 6) {
        return true;
      } else {
        return "Su contraseña debe tener más de 6 caractares.";
      }
    },
    validarcontras: function () {
      if (this.elem.contrasena === this.elem.contrasena2) {
        return true;
      } else {
        return "Las contraseñas no coinciden.";
      }
    },
    mensaje() {
      //this.overlay = true
      this.dialog2 = true;
    },
    validarPep(item) {
      if (item == "SI") {
        this.mostrarPep = true;
      } else {
        this.mostrarPep = false;
      }
    },
    cargarDepartamento() {
      //console.log(ubigeo().resultado.departamentos)
      this.listadepartamentos = ubigeo().resultado.departamentos;
      this.listaprovincias = [];
      this.listadistritos = [];
    },
    cargarProvincia(item) {
      //console.log(item)
      this.listaprovincias = ubigeo().resultado.provincias[item.id_ubigeo];
      this.listadistritos = [];
    },
    cargarDistrito(item) {
      console.log(item);
      this.listadistritos = ubigeo().resultado.distritos[item.id_ubigeo];
    },
  },
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.headerClass {
  text-align: center;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>